import React from 'react';

import { Icon, Button } from 'components';

const TwitterLoginButton = ({
  text = 'Start Analyzing with Fedica',
  uppercase,
}) => {
  return (
    <>
          <a href="https://fedica.com/auth/edioregister"
          style={{
              textTransform: `${uppercase ? 'uppercase' : 'initial'}`, background: 'rgb(28, 161, 242) linear-gradient(to right, rgb(31, 162, 255) 0%, rgb(18, 196, 250) 50%, rgb(31, 162, 255) 100%) 0% 0% / 200%', padding: '10px 20px', 'border-radius': '5px',
              'font-size':'1.5em', 'text-decoration':'none', color:'#fff', 'font-weight':'bold'
          }}
        >
          {text}
          </a >
    </>
  );
};

export default TwitterLoginButton;
