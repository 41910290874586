import React, { useContext, useCallback } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { Box } from 'rebass';
import { ArrowRightCircle } from 'react-feather';

import { Logo } from 'components';
import { MenuContext } from 'context/MenuContext';
import NavWrapper from './styled-components/NavWrapper';

const NavList = ({ showOrHide, dispatch }) => {
  return (
    <>
      <ul style={{ display: showOrHide ? 'block' : 'none' }}>
        <Logo className="mobile-menu-logo" />

        <Box
          as="li"
          sx={{
            position: 'relative',
            ul: {
              padding: 0,
              position: 'relative',
              height: 'auto !important',
              '@media screen and (min-width: 940px)': {
                position: 'absolute',
                visibility: 'hidden',
                width: '375px',
                flexDirection: 'column',
                textAlign: 'left',
                top: '37px',
                background: 'white',
                right: '-3px',
                padding: '10px',
                borderRadius: 'small',
                transition: 'all .3s',
                boxShadow: 'largeArea',
                opacity: 0,
                li: {
                  svg: {
                    position: 'relative',
                    top: '3px',
                  },
                  a: {
                    color: 'black',
                    width: '100%',
                    background: '#efefef',
                    border: 'none !important',
                    mb: '10px',
                    borderRadius: 'small',
                    transition: 'all .3s',
                    '&:hover': {
                      bg: 'purple.0',
                      color: 'white',
                    },
                  },
                  '&:last-child a': {
                    mb: 0,
                  },
                },
              },
            },
            '&:hover': {
              ul: {
                opacity: 1,
                visibility: 'visible',
              },
            },
          }}
        >
          <Link to="/#products" className="products-link">
            Products
          </Link>
          <ul>
            <li>
              <NavLink
                to="/download-twitter-followers"
                onClick={() => dispatch({ type: 'CLOSE_MENU_STATE' })}
              >
                <ArrowRightCircle size="16" /> &nbsp; Analyze Followers &
                Followings
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/download-tweets"
                onClick={() => dispatch({ type: 'CLOSE_MENU_STATE' })}
              >
                <ArrowRightCircle size="16" /> &nbsp; Analyze Historical Tweets
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/download-twitter-account-timeline"
                onClick={() => dispatch({ type: 'CLOSE_MENU_STATE' })}
              >
                <ArrowRightCircle size="16" /> &nbsp; Analyze Account Timeline
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/twitter-influencers"
                onClick={() => dispatch({ type: 'CLOSE_MENU_STATE' })}
              >
                <ArrowRightCircle size="16" /> &nbsp; Find Twitter Influencers
              </NavLink>
            </li>
          </ul>
        </Box>

        {/* <li>
              <NavLink
                exact
                to="/"
                onClick={() => dispatch({ type: 'CLOSE_MENU_STATE' })}
              >
                Home
              </NavLink>
            </li> */}

        <li>
          <a href="/blog/">Blog</a>
        </li>
      </ul>
    </>
  );
};

const Nav = ({ ...props }) => {
  const {
    location: { pathname },
  } = props;
  const { state, dispatch } = useContext(MenuContext);

  const toggleMenu = useCallback(() => {
    dispatch({ type: 'TOGGLE_MENU_STATE' });
  }, [dispatch]);

  return (
    <NavWrapper overlay={!state.open}>
      <div
        style={{
          float: 'left',
          textIndent: '-9999px',
        }}
        role="button"
        tabIndex={0}
        onClick={toggleMenu}
        onKeyDown={toggleMenu}
      >
        <span />
        <i>Menu</i>
      </div>
      <NavList
        showOrHide={state.open}
        pathname={pathname}
        dispatch={dispatch}
      />
    </NavWrapper>
  );
};

export default withRouter(Nav);
