export const pageTitles = {
  /**
   * General Pages
   */
  homepage: 'Analyze Twitter Historical Data',
  trends: '',
  notFound: 'Page Not Found',
  team: 'Team and Careers',
  /**
   * Twitter Landing Pages
   */
  twitterFollowersFollowingLanding: 'Analyze Twitter Followers & Followings',
    twitterAccountTimelineLanding: 'Analyze Twitter Account Timeline',
  twitterInfluencersLanding: 'Find Twitter Influencers by Bio Description',
  twitterTweetsLanding: 'Analyze Historical Tweets by Hashtag or Keyword',
  /**
   * User Dashboard Pages
   */
  accountBilling: 'Plans & Billing',
  accountSettings: 'Settings',
  comingSoon: 'Coming Soon',
  reports: 'Reports',
  twitterFollowersFollowing: 'Analyze Twitter Followers & Followings',
  twitterInfluencers: 'Find Twitter Influencers ',
  twitterTweets: 'Analyze Tweets',
  twitterExportTrends: 'Export Trends',
  /**
   * Additional Products
   */
  nicheDatasets: 'Handcrafted Datasets',
  /**
   * Legal Pages
   */
  terms: 'Terms and Conditions',
  policy: 'Privacy Policy',
  cookies: 'Cookies',
};

export const pageMetaDescriptions = {
  /**
   * General Pages
   */
  homepage:
    'Analyze Twitter followers, followings, account timeline, historical tweets and more.',
  trends: '',
  notFound: '',
  team: 'Meet the team! A group of smiling, hardworking and respectful individuals who trust and care for each other.',
  /**
   * Twitter Landing Pages
   */
  twitterFollowersFollowingLanding:
    'Analyze list of Twitter followers and followings for any public account.',
  twitterAccountTimelineLanding:
    'Analyze all user Tweets of any public Twitter account. In depth Tweet Analysis for marketing and research.',
  twitterInfluencersLanding:
    'Find the right Twitter Influencers and reach your target customers. Apply filters, sort, and export to Excel or CSV formats.',
  twitterTweetsLanding:
    'Access the full tweets archive. Search and analyze tweets that contain specific hashtags or keywords.',
  /**
   * User Dashboard Pages
   */
  accountBilling: '',
  accountSettings: '',
  comingSoon: '',
  reports: '',
  twitterFollowersFollowing: '',
  twitterInfluencers: '',
  twitterTweets: '',
  /**
   * Legal Pages
   */
  terms:
    'Read through ExportData terms and conditions – note that the following terms and conditions govern your access to and use of the website.',
  policy:
    'ExportData privacy policy is meant to help you understand what data we collect, why we collect it, and how it helps simplifying your visits to this site.',
  cookies:
    'ExportData cookie policy is meant to help you understand what cookies we use and why we use them.',
};
